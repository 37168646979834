import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { flow } from 'lodash-es';
import qs from 'query-string';
import getOr from 'lodash/fp/getOr';
import * as zhHant from './zhHant';
import * as en from './en';
import * as zhCn from './zhCn';
import * as vi from './vi';
import * as th from './th';

export const DEFAULT_LANG = 'zh-hant';

const parseLocale = flow<[string], any, string>(
  qs.parse,
  getOr(DEFAULT_LANG, 'locale'),
);

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  resources: {
    'zh-hant': zhHant,
    'zh-cn': zhCn,
    vi,
    th,
    en,
  },
  lowerCaseLng: true,
  fallbackLng: DEFAULT_LANG,
  lng: parseLocale(window.location.search),
  interpolation: {
    escapeValue: false,
  },
  initImmediate: false,
});

export default i18n;
