import { media, Breadcrumb } from '@shopline/dashboard-ui';
import { type IProps as IBreadcrumbProps } from '@shopline/dashboard-ui/types/components/breadcrumb';
import styled from 'styled-components';
import TextLabel from '../TextLabel';

export interface IProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  titleLeftSection?: React.ReactNode;
  titleRightSection?: React.ReactNode;
  breadcrumbs?: IBreadcrumbProps['items'];
}

export const HeaderWrapper = styled.div`
  ${media.mobile`
    padding: 0 1rem;
  `};
`;

const TitleSection = styled.div<{ rightSectionOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ rightSectionOnly }) =>
    rightSectionOnly ? 'flex-end' : 'space-between'};
  flex-wrap: wrap;
  margin: 1rem 0 1.5rem;
`;

const PageHeader: React.FunctionComponent<IProps> = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
}) => (
  <HeaderWrapper>
    {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
    {(titleLeftSection || titleRightSection) && (
      <TitleSection rightSectionOnly={!titleLeftSection && !!titleRightSection}>
        {typeof titleLeftSection === 'string' ? (
          <TextLabel as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
            {titleLeftSection}
          </TextLabel>
        ) : (
          titleLeftSection
        )}
        {titleRightSection}
      </TitleSection>
    )}
  </HeaderWrapper>
);

export default PageHeader;
