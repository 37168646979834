import styled from 'styled-components';
import { LoadingSpinner } from '@shopline/dashboard-ui';
import {
  Selector,
  SelectorWrapper,
} from '@shopline/dashboard-ui/dist/esm/components/dropdown/Dropdown';

const StyledSelector = styled(Selector)`
  background-color: transparent;
  pointer-events: none;
  padding-right: 0.5rem;
`;

const LoadingDropdownSelector: React.FC<
  React.HtmlHTMLAttributes<HTMLDivElement>
> = ({ children }) => (
  <StyledSelector useDefaultBorder disabledShadow>
    <SelectorWrapper>{children}</SelectorWrapper>
    <LoadingSpinner size={20} />
  </StyledSelector>
);

export default LoadingDropdownSelector;
