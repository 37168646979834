import { Text } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

type ITextProps = Props<typeof Text>;

export type IProps = Omit<ITextProps, 'lang'> & {
  as?: keyof JSX.IntrinsicElements;
};

const TextLabel: React.FC<IProps> = (props) => {
  const { i18n } = useTranslation();
  const { language } = i18n as { language: ITextProps['lang'] };
  return <Text lang={language} {...props} />;
};

export default TextLabel;
