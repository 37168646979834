import { createContext, useReducer, useMemo } from 'react';
import { mapValues } from 'lodash-es';
import reducer, { initialState, type ToastState } from './reducer';
import * as actions from './actions';

type Actions = keyof typeof actions;
type ActionsState = {
  [Action in Actions]: (...param: Parameters<(typeof actions)[Action]>) => void;
};

const Context = createContext<Partial<ActionsState>>({});
const StateContext = createContext<ToastState>(initialState);

const Provider: React.FC<{ state?: ToastState; children: React.ReactNode }> = ({
  state: defaultState = initialState,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const memoizedActions = useMemo(
    () =>
      mapValues(actions, (action) => (...param: any[]) => {
        const _action: Function = action;
        dispatch(_action(...param));
      }),
    [dispatch],
  );
  return (
    <Context.Provider value={memoizedActions}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </Context.Provider>
  );
};

export default Context;
export { Provider, StateContext };
