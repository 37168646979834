import { FileErrorTypes } from 'hooks/useSelectFile';
import { XlsxFileErrorTypes } from 'hooks/useSelectXlsxFile';
import { PurchaseOrdersErrorTypes } from 'features/PurchaseOrderBulkImport/constants';

const purchaseOrderBulkImport = {
  Inventory: '库存管理',
  'Purchase Order': '进货单',
  'Page Create Title': '大量建立进货单',
  'Page Update Title': '大量更新进货单',
  'ID Reference File': 'ID 对照档案',
  'Id Reference Section Title': '下载 ID 对照档案',
  'Export purchase order ID reference file': '汇出进货单 ID 对照档案',
  'Id Reference Section Description':
    '请先下载 ID 对照档案，里面包含分店信息、在职员工信息以及商品与规格，方便您对照名称与 ID 进行大量汇入进货单的编辑。如您商品、规格数多，建议您先筛选部分商品再进行下载。',
  'Sample Section Title': '下载范例和档案',
  'Create Sample Section Description1':
    '点击下方「下载范例档案」，帮助您了解如何大量建立进货单。',
  'Create Sample Section Description2':
    '请将您的进货单资料填写于档案中名为 “Template” 的工作表中，系统将只会读取第一张表，故请确保您的档案第一张表为正确的格式。每次最多可建立 50 张进货单，您可以参考档案中的 “Instructions” 及 “Examples” 工作表的指示进行填写。',
  'Update Sample Section Description1':
    '点击下方「选择欲更新的进货单」，将于您选择完希望编辑的多张进货单后，直接汇出既有的进货单与商品明细资讯供您编辑。同时档案中也包含相关说明来帮助您了解如何大量更新多张进货单。您也可以直接点击「下载空白范例档案」来做填写，此档案将不会带入任何进货单的既有资讯。',
  'Update Sample Section Description2':
    '请在 “Purchase Order List” 进行修改，系统将只会读取第一张表，故请确保您的档案第一张表为正确的格式。每次最多可更新 50 张进货单，您可以参考档案中的 “Instructions” 来做填写。',
  'Update Sample Section Description3':
    '注意！您仅可针对货单状态「新开单」与「进货中」的进货单进行大量更新。',
  'Create Sample Section Btn': '下载范例档案',
  'Update Sample Section Btn1': '选择欲更新的进货单',
  'Update Sample Section Btn2': '下载空白范例档案',
  'Upload Section Title': '上传已填写好的档案',
  'Upload Section Description': '选择要上传的档案',
  'Upload Section Hint': '档案大小须小于 10 MB，且仅支援 .xlsx 格式',
  'Upload Section Btn': '上传新档案',
  [`File_Error_${FileErrorTypes.InvalidFileSize}`]: '档案大小不可超过 10 MB',
  [`File_Error_${FileErrorTypes.InvalidFileType}`]: '档案格式需为 .xlsx',
  [`File_Error_${XlsxFileErrorTypes.InvalidContent}`]:
    '档案内容与档案格式皆需为 .xlsx',
  [`File_Error_${PurchaseOrdersErrorTypes.InvalidOrderCounts}`]:
    '进货单数不可超过 50 张。',
  Category: '商品分类',
  Supplier: '供应商',
  'All Product Categories': '全部商品分类',
  'All Suppliers': '全部供应商',
  'No Results': '没有结果',
  'Export Purchase Order Records': '汇出进货纪录',
  'Search Purchase Order Number': '搜寻进货单号',
  'All Stores': '全部分店',
  'View Selected Items': '检视已选项目',
  '{{selected}} / {{total}} selected': '已选 {{selected}} / {{total}}',
  'Purchase Order Number / Custom ID': '进货单号/自订单号',
  'Order Status': '货单状态',
  'Arrival Status': '到货状态',
  'Creation Date': '建立日期',
  'View All Items': '检视所有项目',
  'No purchase order selected': '没有选择进货单',
  'Export bulk update purchase order sample file': '汇出大量更新进货单范例档案',
  'Exporting your bulk update purchase order sample file':
    '正在输出你的大量更新进货单范例档案',
  EXPORT_DESCRIPTION_PURCHASE_ORDERS_REFERENCE:
    '资料正在传送至 {{email}}。在繁忙时间，可能要稍后片刻，请耐心等候。您可以<redirection>点击此处<icon></icon></redirection>查阅汇出进度及下载大量更新进货单范例档案！',
  'No purchase order': '没有进货单',
  'Please go to Inventory Management > Purchase Order to create or import a purchase order':
    '请先至 库存管理 > 进货单 新增或汇入进货单',
  New: '新开单',
  Received: '进货中',
  Removed: '已删除',
  'None arrived': '未到货',
  'Partial arrived': '部分到货',
  'All arrived': '已到货',
  'None returned': '未退货',
  Returned: '已退货',
  'Please try other keywords or search terms': '请试试其他关键字或搜寻条件',
  'Exporting your ID reference file': '正在输出你的进货单 ID 对照档案',
  EXPORT_DESCRIPTION_ID_REFERENCE:
    '资料正在传送至 {{email}}。在繁忙时间，可能要稍后片刻，请耐心等候。您可以<redirection>点击此处<icon></icon></redirection>查阅汇出进度及下载进货单 ID 对照档案！',
  'Failed to upload': '上传失败',
  'Failed to export': '汇出失败',
  'Error description': '发生错误，请确认网路连线后再试一次，或联系线上顾问。',
};

export default purchaseOrderBulkImport;
