import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainRoute from './Main';
import ToastList from './ToastList';
import AppBase from './AppBase';
import NotFound from './NotFound';
import PurchaseOrderBulkImport from './PurchaseOrderBulkImport';

const FeatureRoute = () => (
  <Router basename="/">
    <Switch>
      <Route path="/:merchantId">
        <AppBase>
          <Switch>
            <Route exact path="/:merchantId/purchase-orders/import">
              <PurchaseOrderBulkImport />
            </Route>
            <Route component={MainRoute} />
          </Switch>
        </AppBase>
      </Route>
      <Route component={NotFound} />
    </Switch>
    <Route component={ToastList} />
  </Router>
);

export default FeatureRoute;
