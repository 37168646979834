import { useTranslation } from 'react-i18next';
import { AnchorLink } from '@shopline/dashboard-ui';
import { type LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { useQueryInitialState } from 'hooks/useInitialState';
import {
  Grid,
  Page,
  PageDescription,
  PageTitle,
  // Section,
  // SectionCaption,
} from './components';
import useNav from './useNav';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-index`;

const Main = () => {
  const { t, i18n } = useTranslation(['main', 'pageTitle']);
  const { items } = useNav({ e2ePrefix: `${E2E_PREFIX}-use-nav` });
  const { data, status } = useQueryInitialState();
  if (status !== 'success') {
    return null;
  }
  const {
    merchant: { name: merchantName },
  } = data;

  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <Page
      titleLeftSection={
        <PageTitle>{t('Page Title', { ns: 'pageTitle' })}</PageTitle>
      }
    >
      <PageDescription>
        {t('App Description', { merchantName })}
      </PageDescription>
      <Grid.Row>
        <Grid.Column disableGutter lg={8} md={12} sd={12}></Grid.Column>
        <Grid.Column disableGutter lg={4} md={12} sd={12}>
          <AnchorLink
            lang={i18n.language as LanguageName}
            defaultMessage=""
            scrollbarWrapper={document.getElementsByTagName('html')[0]}
            items={items}
          />
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
  /* eslint-enable jsx-a11y/anchor-has-content */
};

export default Main;
