import { ITableHeadColumn } from '@shopline/dashboard-ui/types/components/table';
import { Checkbox, Table } from '@shopline/dashboard-ui';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import { PurchaseOrder } from 'hooks/useQueryPurchaseOrders';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash-es';
import styled from 'styled-components';
import {
  MaxNumSelectedItems,
  ViewMode,
} from 'features/PurchaseOrderBulkImport/constants';
import {
  arrivalStatusConfig,
  statusConfig,
} from 'features/PurchaseOrderBulkImport/filterConfig';
import ColorBadge from 'components/ColorBadge';
import dayWithLocale from 'utils/day';

export const TableWrapper = styled.div`
  overflow: auto;
`;

const ArrivalStatusBadge = ({
  status,
  label,
}: {
  status: string;
  label: string;
}) => {
  switch (status) {
    case 'pending_to_receive':
    case 'pending_to_return':
    case 'partial':
      return <ColorBadge.Yellow label={label} />;
    case 'all_received':
      return <ColorBadge.Green label={label} />;
    case 'all_returned':
      return <ColorBadge.Red label={label} />;
    default:
      return <ColorBadge.Grey label="Error" />;
  }
};

const OrderStatusBadge = ({
  status,
  label,
}: {
  status: string;
  label: string;
}) => {
  if (status === 'removed') {
    return <ColorBadge.Red label={label} />;
  }
  return <ColorBadge.Blue label={label} />;
};
export interface TableProps {
  purchaseOrders: PurchaseOrder[];
  onPurchaseOrderClick: (ids: string[]) => void;
  selectedPurchaseOrders: PurchaseOrder[];
  viewMode: ViewMode;
}

const PurchaseOrderTable = ({
  purchaseOrders,
  onPurchaseOrderClick,
  selectedPurchaseOrders,
  viewMode,
}: TableProps) => {
  const { t, i18n } = useTranslation(['purchaseOrderBulkImport']);
  const { language } = i18n as { language: LanguageName };
  const selectedPurchaseOrderIdSet = new Set(
    selectedPurchaseOrders.map((order) => order.id),
  );
  const displayPurchaseOrderIds = purchaseOrders.map((order) => order.id);
  const currentSelectedIds = displayPurchaseOrderIds.filter((id) =>
    selectedPurchaseOrderIdSet.has(id),
  );
  const onSelectAllChange = () => {
    const isCancelAll =
      currentSelectedIds.length === purchaseOrders.length ||
      viewMode === 'Selected';
    if (isCancelAll) {
      onPurchaseOrderClick(displayPurchaseOrderIds);
      return;
    }
    const isCancelPartially = displayPurchaseOrderIds.some((id) =>
      isTableRowDisabled(id),
    );
    if (isCancelPartially) {
      onPurchaseOrderClick(currentSelectedIds);
      return;
    }
    const toBeSelectedIds = displayPurchaseOrderIds
      .filter((id) => {
        return !selectedPurchaseOrderIdSet.has(id);
      })
      .slice(0, MaxNumSelectedItems - selectedPurchaseOrders.length);
    onPurchaseOrderClick(toBeSelectedIds);
  };
  const columns: ITableHeadColumn[] = [
    {
      key: 'selection',
      title: (
        <Checkbox.Default
          checked={currentSelectedIds.length > 0}
          interminate={
            currentSelectedIds.length > 0 &&
            currentSelectedIds.length !== purchaseOrders.length
          }
          onChange={onSelectAllChange}
        />
      ),
      width: 2,
    },
    {
      key: 'orderNumber',
      title: t('Purchase Order Number / Custom ID'),
      width: 10,
    },
    { key: 'orderStatus', title: t('Order Status'), width: 8 },
    { key: 'arrivalStatus', title: t('Arrival Status'), width: 8 },
    { key: 'creationDate', title: t('Creation Date'), width: 8 },
  ];
  const isTableRowDisabled = (id: string) =>
    selectedPurchaseOrderIdSet.size >= MaxNumSelectedItems &&
    !selectedPurchaseOrderIdSet.has(id);
  const dataSource = purchaseOrders.map(
    ({ id, number, custom_number, status, arrival_status, created_at }) => ({
      id,
      selection: (
        <Checkbox.Default
          checked={selectedPurchaseOrderIdSet.has(id)}
          disabled={isTableRowDisabled(id)}
        />
      ),
      orderNumber: (
        <>
          {number}
          {custom_number && <div>{custom_number}</div>}
        </>
      ),
      orderStatus: (
        <OrderStatusBadge
          status={status}
          label={t(
            statusConfig.find((config) => config.value === status)?.label || '',
          )}
        />
      ),
      arrivalStatus: (
        <ArrivalStatusBadge
          status={arrival_status}
          label={t(
            arrivalStatusConfig.find(
              (config) => config.value === arrival_status,
            )?.label || '',
          )}
        />
      ),
      creationDate: dayWithLocale(language, created_at).format('LLL'),
    }),
  );
  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={dataSource}
        hoverable={true}
        rowHeight={1.5}
        lang={language}
        onTableRowClick={(_, data) =>
          isTableRowDisabled(data.id) ? noop : onPurchaseOrderClick([data.id])
        }
      />
    </TableWrapper>
  );
};

export default PurchaseOrderTable;
