const common = {
  Test: 'Test',
  Save: '儲存',
  Add: '新增',
  Download: '下載',
  Cancel: '取消',
  Export: '匯出',
  Import: '匯入',
  OK: '確定',
};

export default common;
