import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Button, Popup, TextButton, Paginator } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useContext, useMemo, useState } from 'react';
import useExportPurchaseOrders from 'features/PurchaseOrderBulkImport/hooks/useExportPurchaseOrders';
import TextLabel from 'components/TextLabel';
import useQueryPurchaseOrders, {
  PurchaseOrder,
} from 'hooks/useQueryPurchaseOrders';
import { useQueryInitialState } from 'hooks/useInitialState';
import ToastContext from 'contexts/Toast';
import SearchBar from '../SearchBar';
import EmptyIcon from '../../asset/EmptyIcon.svg';
import NoResultIcon from '../../search_no_result.png';
import { Loading } from 'components';
import PurchaseOrderTable, { TableWrapper } from '../PurchaseOrderTable';
import { MaxNumSelectedItems, ViewMode } from '../../constants';
import ExportDescription, { ExportTypes } from '../ExportDescription';
import E2E_PREFIX from 'features/PurchaseOrderBulkImport/e2ePrefix';

export interface IProps {
  onPopupClose: () => void;
}
const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  button:first-of-type {
    margin-left: 0.625rem;
  }
`;

const ButtonSection = styled.div`
  margin-left: auto;
  display: flex;
  gap: 1rem;
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: -1rem;
`;

const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  width: 200px;
  margin-bottom: 1.5rem;
`;

const ContentWrapper = styled.div<{ isShowPaginator: boolean }>`
  height: calc(100vh - 20rem);
  max-height: 30rem;
  > ${TableWrapper} {
    height: ${({ isShowPaginator }) =>
      isShowPaginator ? 'calc(100% - 36px)' : '100%'};
  }
`;

const PurchaseOrderPopup = ({ onPopupClose }: IProps) => {
  const { t } = useTranslation(['purchaseOrderBulkImport']);
  const { t: commonT } = useTranslation(['common']);
  const toastContext = useContext(ToastContext);
  const { data } = useQueryInitialState();
  const email = data?.staff?.staffEmail || '';
  const [viewMode, setViewMode] = useState<ViewMode>('All');
  const [query, setQuery] = useState('');
  const [channelId, setChannelId] = useState('');
  const [ranges, setRanges] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({ startDate: undefined, endDate: undefined });
  const [page, setPage] = useState(1);
  const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState<
    PurchaseOrder[]
  >([]);

  const { data: purchaseOrders, isFetching } = useQueryPurchaseOrders(
    {
      query,
      channelId,
      page,
      startDate: ranges.startDate,
      endDate: ranges.endDate,
    },
    {
      refetchOnMount: 'always',
    },
  );
  const { mutateAsync: exportPurchaseOrders } = useExportPurchaseOrders({
    ids: selectedPurchaseOrders.map((order) => order.id),
  });

  const displayedPurchaseOrders = useMemo(() => {
    if (viewMode === 'Selected') {
      return selectedPurchaseOrders
        .filter(
          (order) =>
            (query === '' ||
              order.number === query ||
              order.custom_number === query) &&
            (!channelId || order.channel?.id === channelId),
        )
        .filter((order) => {
          if (!ranges.startDate || !ranges.endDate) {
            return true;
          }
          return (
            dayjs(order.created_at).isAfter(
              dayjs(ranges.startDate).startOf('date'),
            ) &&
            dayjs(order.created_at).isBefore(
              dayjs(ranges.endDate)
                .startOf('date')
                .add(1, 'day')
                .subtract(1, 'second'),
            )
          );
        });
    }
    return purchaseOrders?.items || [];
  }, [
    purchaseOrders,
    viewMode,
    selectedPurchaseOrders,
    channelId,
    query,
    ranges.endDate,
    ranges.startDate,
  ]);

  const isEmpty =
    !query &&
    !ranges.startDate &&
    !ranges.endDate &&
    !channelId &&
    displayedPurchaseOrders.length === 0;

  const onPurchaseOrderClick = (orderIds: string[]) => {
    const selectedSet = new Set(
      selectedPurchaseOrders.map((order) => order.id),
    );
    orderIds.forEach((id) => {
      if (selectedSet.has(id)) {
        selectedSet.delete(id);
      } else {
        selectedSet.add(id);
      }
    });
    const existingPurchaseOrders = selectedPurchaseOrders.filter((order) =>
      selectedSet.has(order.id),
    );
    const existingPurchaseOrderIds = existingPurchaseOrders.map(
      (order) => order.id,
    );
    const newSelectedPurchaseOrders = displayedPurchaseOrders.filter(
      (order) =>
        selectedSet.has(order.id) &&
        !existingPurchaseOrderIds.includes(order.id),
    );
    setSelectedPurchaseOrders([
      ...existingPurchaseOrders,
      ...newSelectedPurchaseOrders,
    ]);
  };

  const renderContent = () => {
    if (isEmpty) {
      return (
        <ImageWrapper>
          <img src={EmptyIcon} alt="Empty" />
          {viewMode === 'Selected' ? (
            <TextLabel fontType="Heading" color="INK_600">
              {t('No purchase order selected')}
            </TextLabel>
          ) : (
            <>
              <TextLabel fontType="Heading" color="INK_600">
                {t('No purchase order')}
              </TextLabel>
              <TextLabel fontType="Subheading" color="INK_600">
                {t(
                  'Please go to Inventory Management > Purchase Order to create or import a purchase order',
                )}
              </TextLabel>
            </>
          )}
        </ImageWrapper>
      );
    }
    if (displayedPurchaseOrders.length === 0) {
      return (
        <ImageWrapper>
          <Img src={NoResultIcon} alt="No Result" />
          <TextLabel fontType="Heading" color="INK_600">
            {t('No Results')}
          </TextLabel>
          <TextLabel fontType="Subheading" color="INK_600">
            {t('Please try other keywords or search criteria')}
          </TextLabel>
        </ImageWrapper>
      );
    }
    const isShowPaginator =
      viewMode === 'All' &&
      (purchaseOrders?.pagination?.total_count || 0) > MaxNumSelectedItems;
    return (
      <ContentWrapper isShowPaginator={isShowPaginator}>
        <PurchaseOrderTable
          purchaseOrders={displayedPurchaseOrders}
          onPurchaseOrderClick={onPurchaseOrderClick}
          selectedPurchaseOrders={selectedPurchaseOrders}
          viewMode={viewMode}
        />
        {isShowPaginator && (
          <PaginatorWrapper>
            <Paginator
              windowSize={10}
              totalCount={purchaseOrders?.pagination.total_count || 0}
              onPageChange={(page) => setPage(page)}
              page={page}
              limit={MaxNumSelectedItems}
            />
          </PaginatorWrapper>
        )}
      </ContentWrapper>
    );
  };

  return (
    // @ts-ignore
    <Popup
      overflow="visiable"
      width="45.75rem"
      title={t('Export Purchase Order Records')}
      onClose={onPopupClose}
      showCloseBtn={false}
      renderFooter={() => (
        <FooterWrapper>
          <TextLabel fontType="Body" color="INK_500" weight="Semibold">
            {t('{{selected}} / {{total}} selected', {
              selected: selectedPurchaseOrders.length,
              total: MaxNumSelectedItems,
            })}
          </TextLabel>
          <TextButton.Primary
            onClick={() => {
              setPage(1);
              setViewMode(viewMode === 'All' ? 'Selected' : 'All');
            }}
          >
            {viewMode === 'All'
              ? t('View Selected Items')
              : t('View All Items')}
          </TextButton.Primary>
          <ButtonSection>
            {!isFetching && isEmpty && viewMode === 'All' ? (
              <Button.Primary height="LARGE" onClick={onPopupClose}>
                {commonT('OK')}
              </Button.Primary>
            ) : (
              <>
                <Button.Default
                  type="button"
                  height="LARGE"
                  onClick={onPopupClose}
                >
                  {commonT('Cancel')}
                </Button.Default>
                <Button.Primary
                  e2eId={`${E2E_PREFIX}-download-purchase-orders-reference_button`}
                  disabled={selectedPurchaseOrders.length === 0}
                  height="LARGE"
                  onClick={() => {
                    exportPurchaseOrders()
                      .then(() => {
                        toastContext?.createUIToast?.({
                          type: 'success',
                          duration: 360000,
                          title: t(
                            'Exporting your bulk update purchase order sample file',
                          ),
                          descriptionNodeWithProps: {
                            node: () => (
                              <ExportDescription
                                email={email}
                                type={ExportTypes.PURCHASE_ORDERS_REFERENCE}
                              />
                            ),
                            props: null,
                          },
                        });
                      })
                      .catch(() => {
                        toastContext?.createUIToast?.({
                          type: 'alert',
                          duration: 360000,
                          title: t('Failed to export'),
                          description: t('Error description'),
                        });
                      })
                      .finally(() => onPopupClose());
                  }}
                >
                  {commonT('Export')}
                </Button.Primary>
              </>
            )}
          </ButtonSection>
        </FooterWrapper>
      )}
    >
      <>
        <SearchBar
          setQuery={setQuery}
          setRanges={setRanges}
          setChannelId={setChannelId}
          channelId={channelId}
          setPage={setPage}
          isDisabled={isEmpty}
        />
        {isFetching && <Loading />}
        {!isFetching && renderContent()}
      </>
    </Popup>
  );
};

export default PurchaseOrderPopup;
