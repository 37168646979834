import { Icons, Badge } from '@shopline/dashboard-ui';
import { ColorName } from '@shopline/dashboard-ui/types/themes/colors';
import styled, { css } from 'styled-components';

const IconWrapper = styled.div<{ strokeColor?: ColorName; fill?: ColorName }>`
  display: flex;
  svg {
    width: 0.375rem;
    height: 0.375rem;
  }
  ${({ theme, strokeColor, fill }) => css`
    & rect {
      ${strokeColor ? `stroke: ${theme.colors[strokeColor]}` : ''};
      ${fill ? `fill: ${theme.colors[fill]}` : ''};
    }
  `}
`;

const GreenIcon = () => (
  <IconWrapper>
    <Icons.Solid.BudgeActive size="XXSMALL" />
  </IconWrapper>
);

const GreyIcon = () => (
  <IconWrapper strokeColor="INK_400" fill="INK_200">
    <Icons.Solid.BudgeUnpaid size="XXSMALL" />
  </IconWrapper>
);

const BlueIcon = () => (
  <IconWrapper>
    <Icons.Solid.BudgeBlue size="XXSMALL" />
  </IconWrapper>
);

const YellowIcon = () => (
  <IconWrapper>
    <Icons.Solid.BudgeYellow size="XXSMALL" />
  </IconWrapper>
);

const RedIcon = () => (
  <IconWrapper>
    <Icons.Solid.BudgeLocked size="XXSMALL" />
  </IconWrapper>
);

interface IProps {
  label: string;
}

const Yellow = ({ label }: IProps) => (
  <Badge.Outline
    color="YELLOW_800"
    label={label}
    Icon={YellowIcon}
    borderColor="YELLOW_400"
  />
);

const Blue = ({ label }: IProps) => (
  <Badge.Outline
    color="BLUE_800"
    label={label}
    Icon={BlueIcon}
    borderColor="BLUE_400"
  />
);

const Green = ({ label }: IProps) => (
  <Badge.Outline
    color="GREEN_DEFAULT"
    label={label}
    Icon={GreenIcon}
    borderColor="GREEN_400"
  />
);

const Grey = ({ label }: IProps) => (
  <Badge.Outline
    color="INK_400"
    label={label}
    Icon={GreyIcon}
    borderColor="INK_300"
    backgroundColor="SNOW_500"
  />
);

const Red = ({ label }: IProps) => (
  <Badge.Outline
    color="RED_600"
    label={label}
    Icon={RedIcon}
    borderColor="RED_600"
  />
);

const ColorBadge = {
  Yellow,
  Blue,
  Green,
  Grey,
  Red,
};

export default ColorBadge;
