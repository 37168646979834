import axios from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

export interface TokenResponse {
  url: string;
  token: string;
  fields: {
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Security-Token': string;
    'X-Amz-Signature': string;
    bucket: string;
    key: string;
    'x-amz-server-side-encryption': string;
  };
}

export const fetchToken = ({
  merchantId,
  kind,
}: {
  merchantId: string;
  kind: string;
}) =>
  axios.post<TokenResponse>(`/api/files/upload`, {
    merchant_id: merchantId,
    kind: `bulk-${kind}-purchase-orders`,
  });

const useGeneratePresignedUrl = (kind: string) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useMutation(() => fetchToken({ merchantId, kind }));
};

export default useGeneratePresignedUrl;
