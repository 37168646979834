import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import PostMessageContext from 'contexts/PostMessage';
import { ReactComponent as LinkIcon } from '../../asset/LinkIcon.svg';

export enum ExportTypes {
  ID_REFERENCE = 'ID_REFERENCE',
  PURCHASE_ORDERS_REFERENCE = 'PURCHASE_ORDERS_REFERENCE',
}

const Redirection = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const ExportDescription = ({
  type,
  email,
}: {
  type: ExportTypes;
  email: string;
}) => {
  const { t } = useTranslation(['purchaseOrderBulkImport']);
  const [
    { requestAdminHost: adminHost, requestJobsPath: jobsPath },
    { requestAdminHost, requestJobsPath },
  ] = useContext(PostMessageContext);
  const redirectPath = adminHost + jobsPath;

  useEffect(() => {
    requestAdminHost();
    requestJobsPath();
  }, [requestAdminHost, requestJobsPath]);

  return (
    <Trans
      t={t}
      i18nKey="EXPORT_DESCRIPTION"
      context={type}
      values={{ email }}
      components={{
        redirection: <Redirection target="_blank" href={redirectPath} />,
        icon: <LinkIcon />,
      }}
    ></Trans>
  );
};

export default ExportDescription;
