import axios from 'axios';
import dayjs from 'dayjs';
import { useQuery, UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

export interface PurchaseOrder {
  id: string;
  number: string;
  custom_number: string;
  status: string;
  arrival_status: string;
  updated_at: string;
  created_at: string;
  channel: {
    id: string;
  };
}

interface PurchaseOrdersRequest {
  query: string;
  startDate?: Date;
  endDate?: Date;
  channelId: string;
  merchantId: string;
  page: number;
}

interface PurchaseOrdersResponse {
  items: PurchaseOrder[];
  pagination: {
    current_page: number;
    per_page: number;
    total_count: number;
    total_pages: number;
  };
}

const getPurchaseOrders = async ({
  query,
  channelId,
  startDate,
  endDate,
  merchantId,
  page,
}: PurchaseOrdersRequest): Promise<PurchaseOrdersResponse> => {
  const startDateString = dayjs(startDate).startOf('date').format();
  const endDateString = dayjs(endDate)
    .startOf('date')
    .add(1, 'day')
    .subtract(1, 'second')
    .format();
  const paramObj = {
    per_page: '50',
    query: query,
    channel_id: channelId,
    page: String(page),
    ...(startDate &&
      endDate && {
        start_date: startDateString,
        end_date: endDateString,
      }),
  };
  const params = new URLSearchParams(paramObj);
  params.append('statuses[]', 'pending');
  params.append('statuses[]', 'received');

  const { data } = await axios.get<PurchaseOrdersResponse>(
    `/api/merchants/${merchantId}/purchase-orders`,
    { params },
  );
  return data;
};

const useQueryPurchaseOrders = (
  {
    query,
    startDate,
    endDate,
    channelId,
    page,
  }: Omit<PurchaseOrdersRequest, 'merchantId'>,
  options?: Pick<
    UseQueryOptions<PurchaseOrdersResponse, Error>,
    'refetchOnMount'
  >,
) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<PurchaseOrdersResponse, Error>(
    ['purchaseOrders', { query, startDate, endDate, channelId, page }],
    () => {
      return getPurchaseOrders({
        query,
        channelId,
        startDate,
        endDate,
        merchantId,
        page,
      });
    },
    {
      enabled: !!merchantId,
      ...options,
    },
  );
};

export default useQueryPurchaseOrders;
