import { useCallback, useRef, useState } from 'react';

export enum FileErrorTypes {
  InvalidFileSize = 'Invalid File Size',
  InvalidFileType = 'Invalid File Type',
}

export type UploadFile<TError = string> = {
  name: string;
  isInvalid: boolean;
  file?: File;
  errors: Array<string | FileErrorTypes | TError>;
};

export type UseSelectFileResponse<TError> = {
  fileInputRef: React.RefObject<HTMLInputElement>;
  uploadedFile: UploadFile<TError>;
  acceptFileFormatedTypes: string;
  onChange: (file?: File) => Promise<void>;
};
type OnFileChange<TError> = (
  file: File,
  errors: Array<FileErrorTypes>,
) => Promise<Pick<UploadFile<TError>, 'errors'>>;

function useSelectFile<TError>({
  maxSize,
  acceptFileTypes,
  onFileChange,
}: {
  maxSize?: number;
  acceptFileTypes?: string[];
  onFileChange?: OnFileChange<TError>;
}): UseSelectFileResponse<TError> {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<UploadFile<TError>>({
    name: '',
    isInvalid: false,
    errors: [],
  });

  const acceptFileFormatedTypes =
    acceptFileTypes?.map((type) => `.${type}`)?.join(',') || '';

  const onChange = useCallback(
    async (currentFile?: File) => {
      if (!onFileChange || !currentFile) {
        setUploadedFile({
          name: '',
          isInvalid: false,
          errors: [],
        });
        return;
      }
      const basicErrors = [];
      if (maxSize && maxSize * 1024 * 1024 < currentFile.size) {
        basicErrors.push(FileErrorTypes.InvalidFileSize);
      }
      const regex = /(?:\.([^.]+))?$/;
      const extension =
        regex.exec(currentFile.name)?.[1] || 'unknown extension';
      if (acceptFileTypes && !acceptFileTypes.includes(extension)) {
        basicErrors.push(FileErrorTypes.InvalidFileType);
      }
      const { errors: advancedErrors } = await onFileChange(
        currentFile,
        basicErrors,
      );
      const totalErrors = [...basicErrors, ...advancedErrors];
      setUploadedFile({
        name: currentFile.name,
        isInvalid: !!totalErrors.length,
        errors: totalErrors,
        file: totalErrors.length ? undefined : currentFile,
      });
    },
    [maxSize, onFileChange, acceptFileTypes],
  );

  return {
    fileInputRef,
    uploadedFile,
    acceptFileFormatedTypes,
    onChange,
  };
}

export default useSelectFile;
