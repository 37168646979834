import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash-es';
import styled from 'styled-components';
import { Button, LoadingSpinner, Popup } from '@shopline/dashboard-ui';
import { Dropdown } from 'components';
import useExportPurchaseOrderReference from 'features/PurchaseOrderBulkImport/hooks/useExportPurchaseOrderReference';
import useQueryProductCategories from 'hooks/useQueryProductCategories';
import useQuerySuppliers from 'hooks/useQuerySuppliers';
import { useQueryInitialState } from 'hooks/useInitialState';
import ToastContext from 'contexts/Toast';
import { All, Platforms } from 'features/PurchaseOrderBulkImport/constants';
import ExportDescription, { ExportTypes } from '../ExportDescription';
import E2E_PREFIX from 'features/PurchaseOrderBulkImport/e2ePrefix';
import { curriedDropdownOptionsSelector } from './selector';

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  height: 13.75rem;
`;
const LoadingWrapper = styled.div`
  margin: auto;
`;

export interface IProps {
  e2ePrefix: string;
  onPopupClose: () => void;
}

const IdReferencePopup = ({ onPopupClose, e2ePrefix }: IProps) => {
  const { t, i18n } = useTranslation(['purchaseOrderBulkImport']);
  const { t: commonT } = useTranslation(['common']);
  const [selectedCategory, setSelectedCategory] = useState(All);
  const [selectedSupplier, setSelectedSupplier] = useState(All);
  const { language } = i18n;
  const toastContext = useContext(ToastContext);
  const { data } = useQueryInitialState();
  const email = data?.staff.staffEmail ?? '';
  const channelPlatforms = data?.merchant.rolloutKeys.includes(
    'sl_pos_purchase_order_onlinestore',
  )
    ? [Platforms.Online, Platforms.ShopCrm]
    : [Platforms.ShopCrm];
  const noResultOption = {
    key: 'No Results',
    value: 'No Results',
    label: t('No Results'),
  };

  const { isLoading: getCategoriesLoading, data: categories } =
    useQueryProductCategories({
      select: curriedDropdownOptionsSelector({
        allOptionLabel: t('All Product Categories'),
        language,
        e2ePrefix: `${e2ePrefix}-categories`,
      }),
    });
  const { isLoading: getSuppliersLoading, data: suppliers } = useQuerySuppliers(
    {
      select: curriedDropdownOptionsSelector({
        allOptionLabel: t('All Suppliers'),
        language,
        e2ePrefix: `${e2ePrefix}-suppliers`,
      }),
    },
  );

  const { mutateAsync: exportReference } = useExportPurchaseOrderReference({
    selectedCategory,
    selectedSupplier,
    channelPlatforms,
  });
  const handleCategoryChange = (optionValues: string[]) => {
    const categories = optionValues[0];
    setSelectedCategory(categories);
  };
  const handleSupplierChange = (optionValues: string[]) => {
    const supplier = optionValues[0];
    setSelectedSupplier(supplier);
  };
  const onDownloadClick = () => {
    exportReference()
      .then(() => {
        toastContext?.createUIToast?.({
          type: 'success',
          duration: 360000,
          title: t('Exporting your ID reference file'),
          descriptionNodeWithProps: {
            node: () => (
              <ExportDescription
                email={email}
                type={ExportTypes.ID_REFERENCE}
              />
            ),
            props: null,
          },
        });
      })
      .catch(() => {
        toastContext?.createUIToast?.({
          type: 'alert',
          duration: 360000,
          title: t('Failed to export'),
          description: t('Error description'),
        });
      })
      .finally(() => onPopupClose());
  };
  return (
    // @ts-ignore
    <Popup
      overflow="visible"
      width="43.75rem"
      title={t('Id Reference Section Title')}
      showCloseBtn={false}
      onClose={onPopupClose}
      renderFooter={() => (
        <>
          <Button.Default type="button" height="LARGE" onClick={onPopupClose}>
            {commonT('Cancel')}
          </Button.Default>
          <Button.Primary
            height="LARGE"
            onClick={onDownloadClick}
            e2eId={`${E2E_PREFIX}-download-id-reference_button`}
          >
            {commonT('Download')}
          </Button.Primary>
        </>
      )}
    >
      <Content>
        {getCategoriesLoading || getSuppliersLoading ? (
          <LoadingWrapper>
            <LoadingSpinner />
          </LoadingWrapper>
        ) : (
          <>
            <Dropdown
              width="15.875rem"
              enableSearch
              useDefaultBorder
              disabledShadow
              onClose={noop}
              placeHolder={t('Category')}
              options={categories || []}
              filterOption={(option, value) => option.label.includes(value)}
              minHeight="2rem"
              optionMaxHight="15rem"
              onChange={handleCategoryChange}
              value={[selectedCategory]}
              menuAlign="left"
              optionPlaceholder={noResultOption}
              e2eId={`${e2ePrefix}-categories-dropdown`}
            />
            <Dropdown
              width="15.875rem"
              enableSearch
              useDefaultBorder
              disabledShadow
              onClose={noop}
              placeHolder={t('Supplier')}
              options={suppliers || []}
              filterOption={(option, value) => option.label.includes(value)}
              minHeight="2rem"
              optionMaxHight="15rem"
              onChange={handleSupplierChange}
              value={[selectedSupplier]}
              menuAlign="left"
              optionPlaceholder={noResultOption}
              e2eId={`${e2ePrefix}-suppliers-dropdown`}
            />
          </>
        )}
      </Content>
    </Popup>
  );
};

export default IdReferencePopup;
