import isEmpty from 'lodash-es/isEmpty';

export const SUPPORTED_LNGS = {
  En: 'en',
  ZhCn: 'zh-cn',
  ZhHant: 'zh-hant',
} as const;

export const getTranslation = (
  nameTranslations: Record<string, string>,
  currentLang: string,
) => {
  if (!nameTranslations) {
    return '';
  }
  switch (currentLang) {
    case SUPPORTED_LNGS.ZhHant:
      return (
        nameTranslations[currentLang] ||
        nameTranslations[SUPPORTED_LNGS.ZhCn] ||
        nameTranslations[SUPPORTED_LNGS.En] ||
        ''
      );
    case SUPPORTED_LNGS.ZhCn:
      return (
        nameTranslations[currentLang] ||
        nameTranslations[SUPPORTED_LNGS.ZhHant] ||
        nameTranslations[SUPPORTED_LNGS.En] ||
        ''
      );
    case SUPPORTED_LNGS.En:
      return (
        nameTranslations[currentLang] ||
        nameTranslations[SUPPORTED_LNGS.ZhHant] ||
        nameTranslations[SUPPORTED_LNGS.ZhCn] ||
        ''
      );
    default:
      return (
        nameTranslations[SUPPORTED_LNGS.ZhHant] ||
        nameTranslations[SUPPORTED_LNGS.ZhCn] ||
        nameTranslations[SUPPORTED_LNGS.En] ||
        ''
      );
  }
};

export const getTranslationList = (
  nameTranslations: Record<string, string[]>,
  currentLang: string,
) => {
  if (!nameTranslations) {
    return [];
  }
  switch (currentLang) {
    case SUPPORTED_LNGS.ZhHant:
      return (
        [
          nameTranslations[currentLang],
          nameTranslations[SUPPORTED_LNGS.ZhCn],
          nameTranslations[SUPPORTED_LNGS.En],
        ].find((stringList) => stringList.some((string) => !isEmpty(string))) ||
        []
      );
    case SUPPORTED_LNGS.ZhCn:
      return (
        [
          nameTranslations[currentLang],
          nameTranslations[SUPPORTED_LNGS.ZhHant],
          nameTranslations[SUPPORTED_LNGS.En],
        ].find((stringList) => stringList.some((string) => !isEmpty(string))) ||
        []
      );
    case SUPPORTED_LNGS.En:
      return (
        [
          nameTranslations[currentLang],
          nameTranslations[SUPPORTED_LNGS.ZhHant],
          nameTranslations[SUPPORTED_LNGS.ZhCn],
        ].find((stringList) => stringList.some((string) => !isEmpty(string))) ||
        []
      );
    default:
      return (
        [
          nameTranslations[SUPPORTED_LNGS.ZhHant],
          nameTranslations[SUPPORTED_LNGS.ZhCn],
          nameTranslations[SUPPORTED_LNGS.En],
        ].find((stringList) => stringList.some((string) => !isEmpty(string))) ||
        []
      );
  }
};
