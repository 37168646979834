import axios from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface ExportReference {
  job: {
    locale?: string;
    title_translation: {
      [lang: string]: string;
    };
    type: 'purchase_order';
    total_count: 1;
  };
  data: {
    kind: string;
    fields?: string[];
    filters?: { key: string; values: string[] }[];
  }[];
}

const exportReference = async (
  merchantId: string,
  payload: ExportReference,
): Promise<void> => {
  const { data } = await axios.post(
    `/api/merchants/${merchantId}/jobs/export-reference`,
    payload,
  );
  return data;
};

const useExportReference = (payload: ExportReference) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { i18n } = useTranslation()
  return useMutation(() => exportReference(merchantId, {
    ...payload,
    job: {
      ...payload.job,
      ...(payload?.job?.locale ? {} : {
        locale: i18n.language,
      })
    }
  }));
};

export default useExportReference;
