import dayjs from 'dayjs';
import localizeFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import shoplineEn from './locales/shopline-locale-en';
import shoplineTh from './locales/shopline-locale-th';
import shoplineVi from './locales/shopline-locale-vi';
import shoplineZhCn from './locales/shopline-locale-zh-cn';
import shoplineZhHant from './locales/shopline-locale-zh-hant';

dayjs.extend(localizeFormat);
dayjs.extend(duration);

dayjs.locale('en', shoplineEn);
dayjs.locale('zh-hant', shoplineZhHant);
dayjs.locale('zh-cn', shoplineZhCn);
dayjs.locale('vi', shoplineVi);
dayjs.locale('th', shoplineTh);

const dayWithLocale = (locale, date, format) =>
  dayjs(date, format).locale(locale);

export default dayWithLocale;
