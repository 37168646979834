import { read as readXlsx, WorkBook } from 'xlsx';
import useSelectFile, {
  FileErrorTypes,
  UseSelectFileResponse,
} from 'hooks/useSelectFile';

export enum XlsxFileErrorTypes {
  InvalidContent = 'Invalid Content',
}

function useSelectXlsxFile<TError = string>({
  maxSize,
  onReadXlsx,
}: {
  maxSize?: number;
  onReadXlsx?: (workBook: WorkBook) => { errors: TError[] };
}): UseSelectFileResponse<TError> {
  return useSelectFile<TError>({
    maxSize,
    acceptFileTypes: ['xlsx'],
    onFileChange: (file: File, basicErrors: FileErrorTypes[]) => {
      if (basicErrors.includes(FileErrorTypes.InvalidFileType)) {
        return Promise.resolve({
          errors: [],
        });
      }
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = e.target?.result;
            const workbook = readXlsx(data, {
              type: 'binary',
            });
            if (!onReadXlsx) {
              resolve({
                errors: [],
              });
              return;
            }
            const { errors } = onReadXlsx(workbook);
            resolve({
              errors,
            });
          } catch (e) {
            resolve({
              errors: [XlsxFileErrorTypes.InvalidContent],
            });
          }
        };
        reader.readAsBinaryString(file);
      });
    },
  });
}

export default useSelectXlsxFile;
