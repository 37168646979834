export const All = 'All';
export const MaxNumSelectedItems = 50;
export type ViewMode = 'All' | 'Selected';
export enum PurchaseOrdersErrorTypes {
  InvalidOrderCounts = 'Invalid Order Counts',
}
export enum Platforms {
  Online = 'online',
  ShopCrm = 'shop_crm',
}
