import { curryRight } from 'lodash-es';
import { getTranslation } from 'utils/translate';
import { All } from 'features/PurchaseOrderBulkImport/constants';

interface DataSource {
  id: string;
  name_translations: Record<string, string>;
}

export const dropdownOptionsSelector = (
  data: QueryListResult<DataSource>,
  {
    allOptionLabel,
    language,
    e2ePrefix,
  }: {
    allOptionLabel: string;
    language: string;
    e2ePrefix: string;
  },
) => {
  const allOption = {
    key: All,
    value: All,
    e2eId: `${e2ePrefix}-${All}`,
    label: allOptionLabel,
  };
  const options = data.items.map(({ id, name_translations }) => ({
    key: id,
    value: id,
    e2eId: `${e2ePrefix}-${id}`,
    label: getTranslation(name_translations, language),
  }));
  return [allOption, ...options];
};

export const curriedDropdownOptionsSelector = curryRight(
  dropdownOptionsSelector,
);
