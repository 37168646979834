export const statusConfig = [
  {
    key: 'pending',
    value: 'pending',
    label: 'New',
  },
  {
    key: 'received',
    value: 'received',
    label: 'Received',
  },
  {
    key: 'removed',
    value: 'removed',
    label: 'Removed',
  },
];

export const arrivalStatusConfig = [
  {
    key: 'pending_to_receive',
    value: 'pending_to_receive',
    label: 'None arrived',
  },
  {
    key: 'partial',
    value: 'partial',
    label: 'Partial arrived',
  },
  {
    key: 'all_received',
    value: 'all_received',
    label: 'All arrived',
  },
  {
    key: 'pending_to_return',
    value: 'pending_to_return',
    label: 'None returned',
  },
  {
    key: 'all_returned',
    value: 'all_returned',
    label: 'Returned',
  },
];
