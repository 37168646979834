import axios from 'axios';
import { useQuery, QueryFunctionContext } from 'react-query';
import { useParams } from 'react-router-dom';
import transformObjectKeys, { SnakeCase } from 'transform-object-keys';

type QueryKey = ['initial-state', string];

type InitialState = {
  merchant: {
    id: string;
    name: string;
    currentPlanKey: string;
    staffId: string;
    rolloutKeys: string[];
    handle: string;
  };
  staff: {
    staffId: string;
    staffName: string;
    staffEmail: string;
    staffLocaleCode: string;
    isMerchantOwner: boolean;
    isMerchantStaff: boolean;
    isSuperAdmin: boolean;
    isInspector: boolean;
    isMarketer: boolean;
  };
  permissions: {
    [Scope in string]?: Record<string, boolean>;
  };
};

const getInitialState = async (merchantId: string) => {
  const { data } = await axios.get<SnakeCase<InitialState, true>>(
    `/api/initial-state?merchant_id=${merchantId}`,
  );
  return transformObjectKeys(data, { deep: true }) as unknown as InitialState;
};

const passMerchantId =
  (queryFn: typeof getInitialState) =>
  ({ queryKey: [, merchantId] }: QueryFunctionContext<QueryKey>) =>
    queryFn(merchantId);

const composeQueryKey = (merchantId: string): QueryKey => [
  'initial-state',
  merchantId,
];

export const useQueryInitialState = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery(
    composeQueryKey(merchantId),
    passMerchantId(getInitialState),
    {
      enabled: !!merchantId,
    },
  );
};
