import { useState, useEffect, useCallback, memo } from 'react';
import { TooltipView } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { flow, cond, stubTrue, isString, constant, iteratee } from 'lodash-es';

type ITooltipViewProps = Props<typeof TooltipView>;

const UITooltipView = TooltipView as React.FC<
  ITooltipViewProps & React.HtmlHTMLAttributes<HTMLDivElement>
>;

export interface IProps
  extends Pick<ITooltipViewProps, 'text'>,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  width: number | string;
  dropdownRef: React.RefObject<HTMLElement>;
  selectorRef?: React.RefObject<HTMLElement>;
  tooltipDirection?: ITooltipViewProps['direction'];
}

export const renderWidthStyle = ({ width }: Pick<IProps, 'width'>) =>
  `width: ${width};`;

const SelectorContainer = styled.div`
  &,
  & .tooltipViewContainer {
    ${cond([
      [flow(iteratee('width'), isString), renderWidthStyle],
      [stubTrue, constant('')],
    ])}
  }
`;

const TooltipSelectorWrapper: React.FunctionComponent<IProps> = ({
  width,
  dropdownRef,
  selectorRef,
  text,
  tooltipDirection = 'TOP_CENTER',
  children,
}) => {
  const [isOptionOverflow, setIsOptionOverflow] = useState(false);
  const [isOptionHover, setIsOptionHover] = useState(false);

  const onMouseEnter = useCallback(() => setIsOptionHover(true), []);
  const onMouseLeave = useCallback(() => setIsOptionHover(false), []);

  const selector = selectorRef?.current || dropdownRef?.current;

  useEffect(() => {
    if (selector) {
      const { scrollWidth, clientWidth } = selector;
      setIsOptionOverflow(scrollWidth > clientWidth);
    }
  }, [text, selector]);

  /* tslint:disable */
  return (
    <SelectorContainer width={width || ''}>
      <UITooltipView
        type="primary"
        text={text}
        show={isOptionHover && isOptionOverflow}
        direction={tooltipDirection}
        onMouseEnterChildren={onMouseEnter}
        onMouseLeaveChildren={onMouseLeave}
        containerClassName="tooltipViewContainer"
      >
        {children}
      </UITooltipView>
    </SelectorContainer>
  );
};

export default memo(TooltipSelectorWrapper);
