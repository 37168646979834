import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';

export default styled.div`
  flex: 0 0 100%;
  display: flex;
  align-items: flex-start;
  ${media.mobile`
    flex-wrap: wrap;
    width: 100%;
  `}
`;
