import cond from 'lodash/cond';
import iteratee from 'lodash/iteratee';
import stubTrue from 'lodash/stubTrue';
import constant from 'lodash/constant';
import identity from 'lodash/identity';
import prop from 'lodash/fp/prop';
import { IframeMessageTypes } from 'constants/iframeMessageType';
import { PostMessageConfig } from 'contexts/PostMessage/types';

const parseType = <T>(type: string, parse: (data: any) => T) =>
  cond<unknown, { $set: T } | { $apply: typeof identity }>([
    [iteratee({ type }), (data: any) => ({ $set: parse(data) })],
    [stubTrue, constant({ $apply: identity })],
  ]);

const languageChange: PostMessageConfig<string> = {
  initialValue: null,
  dispatch: () => ({ type: IframeMessageTypes.LANGUAGE_CHANGE }),
  parse: parseType<string>(
    IframeMessageTypes.LANGUAGE_CHANGE,
    prop(['payload', 'lang']),
  ),
};

const redirectToInnerPath: PostMessageConfig<string> = {
  initialValue: null,
  dispatch: (path: string) => ({
    type: IframeMessageTypes.REDIRECT_TO_INNER_PATH,
    payload: { path },
  }),
};

const requestAdminHost: PostMessageConfig<string> = {
  initialValue: '',
  dispatch: () => ({ type: IframeMessageTypes.REQUEST_ADMIN_HOST }),
  parse: parseType(
    IframeMessageTypes.REQUEST_ADMIN_HOST,
    prop(['payload', 'host']),
  ),
};

const requestJobsPath: PostMessageConfig<string> = {
  initialValue: '',
  dispatch: () => ({ type: IframeMessageTypes.REQUEST_JOBS_PATH }),
  parse: parseType(
    IframeMessageTypes.REQUEST_JOBS_PATH,
    prop(['payload', 'path']),
  ),
};

const requestPurchaseOrderPath: PostMessageConfig<string> = {
  initialValue: '',
  dispatch: () => ({ type: IframeMessageTypes.REQUEST_PURCHASE_ORDER_PATH }),
  parse: parseType(
    IframeMessageTypes.REQUEST_PURCHASE_ORDER_PATH,
    prop(['payload', 'path']),
  ),
};

const config = {
  languageChange,
  redirectToInnerPath,
  requestAdminHost,
  requestJobsPath,
  requestPurchaseOrderPath,
};

export default config;
