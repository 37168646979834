import axios from 'axios';
import { useMutation } from 'react-query';
import { TokenResponse } from './useGeneratePresignedUrl';

export interface UploadFileRequest {
  url: string;
  fields: TokenResponse['fields'];
  file: File;
}

export const uploadFile = ({ url, fields, file }: UploadFileRequest) => {
  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('file', file);
  return axios.post(url, formData);
};

const useUploadFile = () => {
  return useMutation(uploadFile);
};

export default useUploadFile;
