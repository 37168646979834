import { useContext, useEffect, useState } from 'react';
import noop from 'lodash-es/noop';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BreadcrumbItem } from '@shopline/dashboard-ui/types/components/breadcrumb';
import { Button, Card, Icons } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import dayjs from 'dayjs';
import PostMessageContext from 'contexts/PostMessage';
import Page from 'components/Page';
import useUploadFile from 'features/PurchaseOrderBulkImport/hooks/useUploadFile';
import PageTitle from 'features/Main/components/PageTitle';
import ToastContext from 'contexts/Toast';
import { useQueryInitialState } from 'hooks/useInitialState';
import useGeneratePresignedUrl from './hooks/useGeneratePresignedUrl';
import IdReferencePopup from './components/IdReferencePopup';
import createTemplate from './asset/purchase_order_bulk_create_template.xlsx';
import updateTemplate from './asset/purchase_order_bulk_update_blank.xlsx';
import ExportPurchaseOrderPopup from './components/ExportPurchaseOrderPopup';
import NotFound from '../NotFound';
import useCreateBulkPurchaseOrdersJob from './hooks/useCreateBulkPurchaseOrdersJob';
import E2E_PREFIX from './e2ePrefix';
import useSelectPurchaseOrdersXlsxFile from './hooks/useSelectPurchaseOrdersXlsxFile';

const Mode = {
  Create: 'create',
  Update: 'update',
};

const StyledCard = styled(Card)`
  margin-bottom: 1rem;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
  color: ${({ theme }) => theme.colors.INK_800};
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`;

const SectionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 1rem 0;
`;

const SectionBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: 17.5rem;
  height: 3.25rem;
  border: 1px dashed rgba(204, 223, 255, 1);
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.PRIMARY_200};
  color: ${({ theme }) => theme.colors.PRIMARY_600};
  font-weight: 600;
  font-size: 14px;
  :hover {
    cursor: pointer;
    background-color: rgba(230, 239, 255, 0.4);
    border-color: transparent;
  }
`;

const Buttons = styled.div<{ isUploadBtn?: boolean; hasError?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 1rem;
  a {
    text-decoration: none;
  }
  ${({ isUploadBtn, hasError }) =>
    isUploadBtn && !hasError && 'margin-top: 3rem;'}
`;

const FileSection = styled(SectionDescription)<{ isInvalid: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  height: 10rem;
  border: 1px dashed ${({ theme }) => theme.colors.INK_400};
  :hover {
    cursor: pointer;
  }
  ${({ isInvalid, theme }) =>
    isInvalid &&
    `
    border-color: ${theme.colors.RED_600};
    background-color: ${theme.colors.RED_200};
    color: ${theme.colors.RED_600};
  `}
`;

const Hint = styled.div<{ isInvalid?: boolean }>`
  color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.colors.RED_600 : theme.colors.INK_400};
`;

const FileInput = styled.input`
  display: none;
`;

const DescriptionSteps = styled.ol`
  padding: 0;
  list-style-position: inside;
`;

const ErrorMessage = styled.ul`
  padding: 0;
  margin: 0;
  list-style-position: inside;
  color: ${({ theme }) => theme.colors.RED_700};
`;

const PurchaseOrderBulkImport = () => {
  const [
    {
      requestAdminHost: adminHost,
      requestPurchaseOrderPath: purchaseOrderPath,
      requestJobsPath: jobsPath,
    },
    {
      requestAdminHost,
      requestPurchaseOrderPath,
      redirectToInnerPath,
      requestJobsPath,
    },
  ] = useContext(PostMessageContext);
  const { search } = useLocation();
  const { t } = useTranslation(['purchaseOrderBulkImport']);
  const { t: commonT } = useTranslation(['common']);
  const toastContext = useContext(ToastContext);
  const [isIdReferencePopupOpen, setIsIdReferencePopupOpen] = useState(false);
  const [isPurchaseOrderPopupOpen, setIsPurchaseOrderPopupOpen] =
    useState(false);
  const { data } = useQueryInitialState();
  const {
    fileInputRef,
    uploadedFile: currentFile,
    acceptFileFormatedTypes,
    totalCount,
    onChange,
  } = useSelectPurchaseOrdersXlsxFile();
  const handle = data?.merchant.handle || '';
  const searchParams = new URLSearchParams(search);
  const currentMode = searchParams.get('mode') || '';
  const isValidMode = [Mode.Create, Mode.Update].includes(currentMode);
  useEffect(() => {
    requestAdminHost();
    requestPurchaseOrderPath();
    requestJobsPath();
  }, [requestJobsPath, requestPurchaseOrderPath, requestAdminHost]);
  const {
    mutateAsync: generatePresignedUrl,
    isLoading: isPresignedUrlLoading,
  } = useGeneratePresignedUrl(currentMode);
  const { mutateAsync: uploadFile, isLoading: isUploadFileLoading } =
    useUploadFile();
  const { mutateAsync: createJobRecord } = useCreateBulkPurchaseOrdersJob();

  const isUploading = isPresignedUrlLoading || isUploadFileLoading;
  if (!isValidMode) {
    return <NotFound />;
  }
  const TIMESTAMP_FORMAT = 'YYYYMMDDHHmmss';

  const getPageTitle = () => {
    const titleMap = {
      [Mode.Create]: 'Page Create Title',
      [Mode.Update]: 'Page Update Title',
    };
    return t(titleMap[currentMode]);
  };
  const breadcrumbs: BreadcrumbItem[] = [
    { id: 'inventory', label: t('Inventory') },
    {
      id: 'purchase_order',
      label: t('Purchase Order'),
      url: adminHost,
      onClick: purchaseOrderPath
        ? (e: Event) => {
            e.preventDefault();
            redirectToInnerPath(purchaseOrderPath);
          }
        : noop,
    },
    {
      id: 'purchase_order_bulk_import',
      label: getPageTitle(),
    },
  ];

  return (
    <Page
      breadcrumbs={breadcrumbs}
      titleLeftSection={<PageTitle>{getPageTitle()}</PageTitle>}
    >
      <StyledCard>
        <SectionTitle>
          <Icons.Solid.StepOne color="INK_800" size="XLARGE" />
          <div>{t('Id Reference Section Title')}</div>
        </SectionTitle>
        <SectionDescription>
          {t('Id Reference Section Description')}
        </SectionDescription>
        <Buttons>
          <SectionBtn
            data-e2e-id={`${E2E_PREFIX}-open-id-reference-popup_button`}
            onClick={() => setIsIdReferencePopupOpen(true)}
          >
            <Icons.Outline.Export color="PRIMARY_600" size="SMALL" />
            {t('Id Reference Section Title')}
          </SectionBtn>
        </Buttons>
      </StyledCard>
      <StyledCard>
        <SectionTitle>
          <Icons.Solid.StepTwo color="INK_800" size="XLARGE" />
          <div>{t('Sample Section Title')}</div>
        </SectionTitle>
        <SectionDescription>
          <DescriptionSteps>
            {currentMode === Mode.Create ? (
              <>
                <li>{t('Create Sample Section Description1')}</li>
                <li>{t('Create Sample Section Description2')}</li>
              </>
            ) : (
              <>
                <li>{t('Update Sample Section Description1')}</li>
                <li>{t('Update Sample Section Description2')}</li>
                <li>{t('Update Sample Section Description3')}</li>
              </>
            )}
          </DescriptionSteps>
        </SectionDescription>
        <Buttons>
          {currentMode === Mode.Create ? (
            <Link
              to={createTemplate}
              target="_blank"
              rel="noopener noreferrer"
              download={`${handle}_purchase_order_bulk_create_template_${dayjs().format(
                TIMESTAMP_FORMAT,
              )}.xlsx`}
            >
              <SectionBtn
                data-e2e-id={`${E2E_PREFIX}-download-create-template_button`}
              >
                <Icons.Outline.Export color="PRIMARY_600" size="SMALL" />
                {t('Create Sample Section Btn')}
              </SectionBtn>
            </Link>
          ) : (
            <>
              <SectionBtn
                onClick={() => setIsPurchaseOrderPopupOpen(true)}
                data-e2e-id={`${E2E_PREFIX}-open-purchase-orders-reference-popup_button`}
              >
                <Icons.Outline.Export color="PRIMARY_600" size="SMALL" />
                {t('Update Sample Section Btn1')}
              </SectionBtn>
              <Link
                to={updateTemplate}
                target="_blank"
                rel="noopener noreferrer"
                download={`${handle}_purchase_order_bulk_update_blank_${dayjs().format(
                  TIMESTAMP_FORMAT,
                )}.xlsx`}
              >
                <SectionBtn
                  data-e2e-id={`${E2E_PREFIX}-download-bulk-update_button`}
                >
                  <Icons.Outline.Export color="PRIMARY_600" size="SMALL" />
                  {t('Update Sample Section Btn2')}
                </SectionBtn>
              </Link>
            </>
          )}
        </Buttons>
      </StyledCard>
      <StyledCard>
        <FileInput
          ref={fileInputRef}
          type="file"
          accept={acceptFileFormatedTypes}
          onChange={() => {
            const file = fileInputRef?.current?.files?.[0];
            if (!file) {
              return;
            }
            onChange(file);
          }}
        />

        <SectionTitle>
          <Icons.Solid.StepThree color="INK_800" size="XLARGE" />
          <div>{t('Upload Section Title')}*</div>
        </SectionTitle>
        <FileSection
          isInvalid={currentFile.isInvalid}
          onClick={() => fileInputRef?.current?.click()}
        >
          {!currentFile.name && (
            <Icons.Outline.Plus color="INK_600" size="XLARGE" />
          )}
          {currentFile.name && !currentFile.isInvalid && (
            <Icons.Outline.Plus color="INK_600" size="XLARGE" />
          )}
          {currentFile.name && currentFile.isInvalid && (
            <Icons.Outline.Plus color="RED_600" size="XLARGE" />
          )}
          <div>{currentFile.name || t('Upload Section Description')}</div>
          <Hint isInvalid={currentFile.isInvalid}>
            {t('Upload Section Hint')}
          </Hint>
        </FileSection>
        {currentFile.isInvalid && (
          <ErrorMessage>
            {currentFile.errors.map((error) => (
              <li key={error}>{t('File_Error', { context: error })}</li>
            ))}
          </ErrorMessage>
        )}
        {currentFile.name && (
          <Buttons isUploadBtn hasError={!!currentFile?.errors.length}>
            <SectionBtn onClick={() => fileInputRef?.current?.click()}>
              <Icons.Outline.Import color="PRIMARY_600" size="SMALL" />
              {t('Upload Section Btn')}
            </SectionBtn>
          </Buttons>
        )}
      </StyledCard>
      {currentFile.file && (
        <Buttons>
          <Button.Default height="LARGE" onClick={() => onChange()}>
            {commonT('Cancel')}
          </Button.Default>
          <Button.Primary
            e2eId={`${E2E_PREFIX}-${currentMode}-upload-file_button`}
            height="LARGE"
            disabled={isUploading}
            onClick={async () => {
              try {
                const {
                  data: { url, fields, token },
                } = await generatePresignedUrl();
                await uploadFile({ url, fields, file: currentFile.file! });
                await createJobRecord({
                  fields: { key: fields.key, token },
                  mode: currentMode,
                  totalCount,
                });
                if (jobsPath) {
                  redirectToInnerPath(jobsPath);
                }
              } catch (e) {
                toastContext?.createUIToast?.({
                  type: 'alert',
                  duration: 360000,
                  title: t('Failed to upload'),
                  description: t('Error description'),
                });
              }
            }}
          >
            {commonT('Import')}
          </Button.Primary>
        </Buttons>
      )}
      {isIdReferencePopupOpen && (
        <IdReferencePopup
          onPopupClose={() => setIsIdReferencePopupOpen(false)}
          e2ePrefix={`${E2E_PREFIX}-id-reference-popup`}
        />
      )}
      {isPurchaseOrderPopupOpen && (
        <ExportPurchaseOrderPopup
          onPopupClose={() => setIsPurchaseOrderPopupOpen(false)}
        />
      )}
    </Page>
  );
};

export default PurchaseOrderBulkImport;
