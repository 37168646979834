export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const REQUEST_ADMIN_HOST = 'REQUEST_ADMIN_HOST';
export const REDIRECT_TO_INNER_PATH = 'REDIRECT_TO_INNER_PATH';

export enum IframeMessageTypes {
  LANGUAGE_CHANGE = 'LANGUAGE_CHANGE',
  REQUEST_ADMIN_HOST = 'REQUEST_ADMIN_HOST',
  REDIRECT_TO_INNER_PATH = 'REDIRECT_TO_INNER_PATH',
  REQUEST_JOBS_PATH = 'REQUEST_JOBS_PATH',
  REQUEST_PURCHASE_ORDER_PATH = 'REQUEST_PURCHASE_ORDER_PATH',
}
