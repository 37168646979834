const common = {
  Test: 'Test',
  Save: 'Lưu',
  Download: 'Download',
  Cancel: 'Cancel',
  Export: 'Export',
  Import: 'Import',
  OK: 'OK',
};

export default common;
