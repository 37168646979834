import { FileErrorTypes } from 'hooks/useSelectFile';
import { XlsxFileErrorTypes } from 'hooks/useSelectXlsxFile';
import { PurchaseOrdersErrorTypes } from 'features/PurchaseOrderBulkImport/constants';

const purchaseOrderBulkImport = {
  Inventory: '庫存管理',
  'Purchase Order': '進貨單',
  'Page Create Title': '大量建立進貨單',
  'Page Update Title': '大量更新進貨單',
  'ID Reference File': 'ID 對照檔案',
  'Id Reference Section Title': '下載 ID 對照檔案',
  'Export purchase order ID reference file': '匯出進貨單 ID 對照檔案',
  'Id Reference Section Description':
    '請先下載 ID 對照檔案，裡面包含分店資訊、在職員工資訊以及商品與規格，方便您對照名稱與 ID 進行大量匯入進貨單的編輯。如您商品、規格數多，建議您先篩選部分商品再進行下載。',
  'Sample Section Title': '下載範例和檔案',
  'Create Sample Section Description1':
    '點擊下方「下載範例檔案」，幫助您了解如何大量建立進貨單。',
  'Create Sample Section Description2':
    '請將您的進貨單資料填寫於檔案中名為 “Template” 的工作表中，系統將只會讀取第一張表，故請確保您的檔案第一張表為正確的格式。每次最多可建立 50 張進貨單，您可以參考檔案中的 “Instructions” 及 “Examples” 工作表的指示進行填寫。',
  'Update Sample Section Description1':
    '點擊下方「選擇欲更新的進貨單」，將於您選擇完希望編輯的多張進貨單後，直接匯出既有的進貨單與商品明細資訊供您編輯。同時檔案中也包含相關說明來幫助您了解如何大量更新多張進貨單。您也可以直接點擊「下載空白範例檔案」來做填寫，此檔案將不會帶入任何進貨單的既有資訊。',
  'Update Sample Section Description2':
    '請在 “Purchase Order List” 進行修改，系統將只會讀取第一張表，故請確保您的檔案第一張表為正確的格式。每次最多可更新 50 張進貨單，您可以參考檔案中的 “Instructions” 來做填寫。',
  'Update Sample Section Description3':
    '注意！您僅可針對貨單狀態「新開單」與「進貨中」的進貨單進行大量更新。',
  'Create Sample Section Btn': '下載範例檔案',
  'Update Sample Section Btn1': '選擇欲更新的進貨單',
  'Update Sample Section Btn2': '下載空白範例檔案',
  'Upload Section Title': '上傳已填寫好的檔案',
  'Upload Section Description': '選擇要上傳的檔案',
  'Upload Section Hint': '檔案大小須小於 10 MB，且僅支援 .xlsx 格式',
  'Upload Section Btn': '上傳新檔案',
  [`File_Error_${FileErrorTypes.InvalidFileSize}`]: '檔案大小不可超過 10 MB',
  [`File_Error_${FileErrorTypes.InvalidFileType}`]: '檔案格式需為 .xlsx',
  [`File_Error_${XlsxFileErrorTypes.InvalidContent}`]:
    '檔案內容與檔案格式皆需為 .xlsx',
  [`File_Error_${PurchaseOrdersErrorTypes.InvalidOrderCounts}`]:
    '進貨單數不可超過 50 張。',
  'Upload Section Error Msg': '進貨單數不可超過 50 張。',
  Category: '商品分類',
  Supplier: '供應商',
  'All Product Categories': '全部商品分類',
  'All Suppliers': '全部供應商',
  'No Results': '没有结果',
  'Export Purchase Order Records': '匯出進貨紀錄',
  'Search Purchase Order Number': '搜尋進貨單號',
  'All Stores': '全部分店',
  'View Selected Items': '檢視已選項目',
  '{{selected}} / {{total}} selected': '已選 {{selected}} / {{total}}',
  'Purchase Order Number / Custom ID': '進貨單號/自訂單號',
  'Order Status': '貨單狀態',
  'Arrival Status': '到貨狀態',
  'Creation Date': '建立日期',
  'View All Items': '檢視所有項目',
  'No purchase order selected': '沒有選擇進貨單',
  'Export bulk update purchase order sample file': '匯出大量更新進貨單範例檔案',
  'Exporting your bulk update purchase order sample file':
    '正在輸出你的大量更新進貨單範例檔案',
  EXPORT_DESCRIPTION_PURCHASE_ORDERS_REFERENCE:
    '資料正在傳送至 {{email}}。在繁忙時間，可能要稍後片刻，請耐心等候。您可以<redirection>點擊此處<icon></icon></redirection>查閱匯出進度及下載大量更新進貨單範例檔案！',
  'No purchase order': '沒有進貨單',
  'Please go to Inventory Management > Purchase Order to create or import a purchase order':
    '請先至 庫存管理 > 進貨單 新增或匯入進貨單',
  New: '新開單',
  Received: '進貨中',
  Removed: '已刪除',
  'None arrived': '未到貨',
  'Partial arrived': '部分到貨',
  'All arrived': '已到貨',
  'None returned': '未退貨',
  Returned: '已退貨',
  'Please try other keywords or search criteria': '請試試其他關鍵字或搜尋條件',
  'Exporting your ID reference file': '正在輸出你的進貨單 ID 對照檔案',
  EXPORT_DESCRIPTION_ID_REFERENCE:
    '資料正在傳送至 {{email}}。在繁忙時間，可能要稍後片刻，請耐心等候。您可以<redirection>點擊此處<icon></icon></redirection>查閱匯出進度及下載進貨單 ID 對照檔案！',
  'Failed to upload': '上傳失敗',
  'Failed to export': '匯出失敗',
  'Error description': '發生錯誤，請確認網路連線後再試一次，或聯繫線上顧問。',
};

export default purchaseOrderBulkImport;
