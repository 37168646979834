const common = {
  Test: '測試',
  Save: '储存',
  Download: '下载',
  Cancel: '取消',
  Export: '汇出',
  Import: '汇入',
  OK: '确定',
};

export default common;
