import { useTranslation } from 'react-i18next';
import useExportReference, { ExportReference } from './useExportReference';

const useExportPurchaseOrders = ({ ids }: { ids: string[] }) => {
  const { t } = useTranslation(['purchaseOrderBulkImport']);
  const payload: ExportReference = {
    job: {
      title_translation: {
        'zh-hant': t('Export bulk update purchase order sample file', {
          lng: 'zh-hant',
        }),
        'zh-cn': t('Export bulk update purchase order sample file', {
          lng: 'zh-cn',
        }),
        en: t('Export bulk update purchase order sample file', { lng: 'en' }),
      },
      type: 'purchase_order',
      total_count: 1,
    },
    data: [
      {
        kind: 'bulk-update-purchase-order',
        filters: [{ key: 'ids', values: ids }],
      },
    ],
  };
  return useExportReference(payload);
};

export default useExportPurchaseOrders;
