import styled from 'styled-components';
import { media, Footer } from '@shopline/dashboard-ui';
import PageHeader, { type IProps as IPageHeaderProps } from '../PageHeader';

const PageWrapper = styled.div`
  padding: 1.5rem 2rem;
  ${media.mobile`
    padding: 1.5rem 0;
  `}
`;

interface IProps
  extends Pick<
      IPageHeaderProps,
      'titleLeftSection' | 'titleRightSection' | 'breadcrumbs'
    >,
    React.HtmlHTMLAttributes<HTMLDivElement> {
  hideFooter?: boolean;
}

const Page: React.FunctionComponent<IProps> = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
  hideFooter,
  className,
  children,
}) => (
  <PageWrapper className={className}>
    <PageHeader
      titleLeftSection={titleLeftSection}
      titleRightSection={titleRightSection}
      breadcrumbs={breadcrumbs}
    />
    {children}
    {!hideFooter && <Footer />}
  </PageWrapper>
);

export default Page;
