import Dropdown from 'components/Dropdown';
import { withProps } from 'recompose';

type IDropdownProps = Props<typeof Dropdown>;

type InjectedProps = {
  width: string;
  block: boolean;
  minHeight: string;
  useDefaultBorder: boolean;
  disabledShadow: boolean;
};

const enhance = withProps<
  InjectedProps,
  Omit<IDropdownProps, keyof InjectedProps>
>({
  width: '100%',
  block: true,
  minHeight: '2.25rem',
  useDefaultBorder: true,
  disabledShadow: true,
});

export default enhance(
  Dropdown as React.FC<
    Omit<IDropdownProps, keyof InjectedProps> & InjectedProps
  >,
);
