import { FileErrorTypes } from 'hooks/useSelectFile';
import { XlsxFileErrorTypes } from 'hooks/useSelectXlsxFile';
import { PurchaseOrdersErrorTypes } from 'features/PurchaseOrderBulkImport/constants';

const purchaseOrderBulkImport = {
  Inventory: 'Inventory',
  'Purchase Order': 'Purchase Order',
  'Page Create Title': 'Bulk Create Purchase Order',
  'Page Update Title': 'Bulk Update Purchase Order',
  'ID Reference File': 'ID Reference File',
  'Id Reference Section Title': 'Download ID Reference File',
  'Export purchase order ID reference file':
    'Export purchase order ID reference file',
  'Id Reference Section Description':
    'Please download the ID reference file containing store information, active employees, products and variations to confirm names and IDs and bulk create purchase orders. Please select partial products before downloading if the store contains many products and variations.',
  'Sample Section Title': 'Download sample file',
  'Create Sample Section Description1':
    'Click Download Sample File and read it thoroughly to help you know how to bulk create purchase order',
  'Create Sample Section Description2':
    'Please fill in your purchase order information in the "Template" sheet in the correct format. A maximum of 50 purchase orders can be uploaded at once. Please refer to the "Instructions" and "Examples" sheets for data-filling.',
  'Update Sample Section Description1':
    'Click Select Purchase Order To Update below. After selecting the purchase orders, the respective purchase orders and product information will be exported for you to edit. Instructions are included in the exported file to help you know the update steps. You can also click Download Sample File to fill in a template.',
  'Update Sample Section Description2':
    'Please make edits the "Purchase Order List" sheet. As the system only reads the first sheet, please make sure it is in the correct format. A maximum of 50 purchase orders can be uploaded at once. Please refer to the "Instructions" and "Examples" sheets for field-filling.',
  'Update Sample Section Description3':
    'Note: You can only bulk update "New" and "Received" purchase orders.',
  'Create Sample Section Btn': 'Download Sample File',
  'Update Sample Section Btn1': 'Select Purchase Order To Update',
  'Update Sample Section Btn2': 'Download Sample File',
  'Upload Section Title': 'Upload the completed file',
  'Upload Section Description': 'Select file to upload',
  'Upload Section Hint':
    'File size must be less than 10 MB and only in .xlsx format',
  'Upload Section Btn': 'Upload new file',
  [`File_Error_${FileErrorTypes.InvalidFileSize}`]:
    'The file size cannot exceed 10 MB.',
  [`File_Error_${FileErrorTypes.InvalidFileType}`]:
    'The file format should be .xlsx.',
  [`File_Error_${XlsxFileErrorTypes.InvalidContent}`]:
    'The file content and file format should be .xlsx.',
  [`File_Error_${PurchaseOrdersErrorTypes.InvalidOrderCounts}`]:
    'The maximum limit of purchase orders is 50.',
  Category: 'Category',
  Supplier: 'Supplier',
  'All Product Categories': 'All Product Categories',
  'All Suppliers': 'All Suppliers',
  'No Results': 'No Results',
  'Export Purchase Order Records': 'Export Purchase Order Records',
  'Search Purchase Order Number': 'Search Purchase Order Number',
  'All Stores': 'All Stores',
  'View Selected Items': 'View Selected Items',
  '{{selected}} / {{total}} selected': '{{selected}} / {{total}} selected',
  'Purchase Order Number / Custom ID': 'Purchase Order Number / Custom ID',
  'Order Status': 'Order Status',
  'Arrival Status': 'Arrival Status',
  'Creation Date': 'Creation Date',
  'View All Items': 'View All Items',
  'No purchase order selected': 'No purchase order selected',
  'Export bulk update purchase order sample file':
    'Export bulk update purchase order sample file',
  'Exporting your bulk update purchase order sample file':
    'Exporting your bulk update purchase order sample file',
  EXPORT_DESCRIPTION_PURCHASE_ORDERS_REFERENCE:
    'Sending data to {{email}}. At peak times this may take a while. Please be patient while waiting. You can check exporting progress <redirection>here<icon></icon></redirection> and download the bulk update purchase order sample file directly',
  'No purchase order': 'No purchase order',
  'Please go to Inventory Management > Purchase Order to create or import a purchase order':
    'Please go to Inventory Management > Purchase Order to create or import a purchase order',
  New: 'New',
  Received: 'Received',
  Removed: 'Removed',
  'None arrived': 'None arrived',
  'Partial arrived': 'Partial arrived',
  'All arrived': 'All arrived',
  'None returned': 'None returned',
  Returned: 'Returned',
  'Please try other keywords or search criteria':
    'Please try other keywords or search criteria',
  'Exporting your ID reference file': 'Exporting your ID reference file',
  EXPORT_DESCRIPTION_ID_REFERENCE:
    'Sending data to {{email}}. At peak times this may take a while. Please be patient while waiting. You can check exporting progress <redirection>here<icon></icon></redirection> and download the ID reference file directly.',
  'Failed to upload': 'Failed to upload',
  'Failed to export': 'Failed to export',
  'Error description':
    'Error. Please check your internet connection and try again, or contact the Online Merchant Success Team.',
};

export default purchaseOrderBulkImport;
