import { QueryClient } from 'react-query';
import { conforms, identity, overEvery } from 'lodash-es';
import lte from 'lodash/fp/lte';
import gt from 'lodash/fp/gt';

const shouldRetry = conforms<unknown>({
  isAxiosError: identity,
  response: conforms({
    status: overEvery([lte(400), gt(500)]),
  }),
});

const shouldRetryFactory = ({ max = 3, skip4xx = true } = {}) => {
  const maxCount = max - 1;
  return (count: number, error: unknown) => {
    if (skip4xx && shouldRetry(error)) {
      return false;
    }
    return count < maxCount;
  };
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      staleTime: Number.POSITIVE_INFINITY,
      refetchOnWindowFocus: false,
      retry: shouldRetryFactory(),
    },
  },
});

export default queryClient;
