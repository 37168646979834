import styled from 'styled-components';
import Text from 'components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PageTitle: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  children,
}) => (
  <Container>
    <Text as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
      {children}
    </Text>
  </Container>
);

export default PageTitle;
