import { OptionProps } from '@shopline/dashboard-ui/types/components/dropdown/Dropdown';
import axios from 'axios';
import { Platforms } from 'features/PurchaseOrderBulkImport/constants';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export interface Channels {
  items: {
    id: string;
    name_translations: { [lang: string]: string };
    name: string;
    updated_at: string;
    created_at: string;
  }[];
}

const getChannels = async (
  merchantId: string,
  params: { [key: string]: string[] } = {},
): Promise<Channels> => {
  const { data } = await axios.get<Channels>(
    `/api/merchants/${merchantId}/channels`,
    {
      params: {
        platforms: [Platforms.ShopCrm],
        ...params,
      },
    },
  );
  return data;
};

const useQueryChannels = ({
  select,
  params,
}: {
  select?: (items: Channels) => OptionProps[];
  params?: { [key: string]: string[] };
}) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<Channels, Error, OptionProps[]>(
    'channels',
    () => getChannels(merchantId, params),
    {
      select,
      enabled: !!merchantId,
    },
  );
};

export default useQueryChannels;
