import { OptionProps } from '@shopline/dashboard-ui/types/components/dropdown/Dropdown';
import axios from 'axios';
import { useQuery, type UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

export type Supplier = {
  id: string;
  name_translations: { [lang: string]: string };
  name: string;
};

export interface Suppliers extends QueryListResult<Supplier> {}

const getSupplier = async (merchantId: string): Promise<Suppliers> => {
  const { data } = await axios.get<Suppliers>(
    `/api/merchants/${merchantId}/suppliers?sort_by=created_at.desc`,
  );
  return data;
};

const useQuerySuppliers = (
  options: Pick<UseQueryOptions<Suppliers, Error, OptionProps[]>, 'select'>,
) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<Suppliers, Error, OptionProps[]>(
    'suppliers',
    () => getSupplier(merchantId),
    {
      enabled: !!merchantId,
      ...options,
    },
  );
};

export default useQuerySuppliers;
