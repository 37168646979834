import axios from 'axios';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { type TFunction } from 'i18next';

type Payload = { key: string; token: string };

const createJob = ({
  merchantId,
  fields,
  mode,
  totalCount,
  translate,
  locale,
}: {
  merchantId: string;
  fields: Payload;
  mode: string;
  totalCount: number;
  translate: TFunction;
  locale: string;
}) => {
  const { key, token } = fields;

  const titleKey =
    mode === 'create' ? 'Page Create Title' : 'Page Update Title';

  return axios.post(
    `/api/merchants/${merchantId}/jobs/bulk-${mode}-purchase-orders`,
    {
      job: {
        title_translation: {
          'zh-hant': translate(titleKey, { lng: 'zh-hant' }),
          'zh-cn': translate(titleKey, { lng: 'zh-cn' }),
          en: translate(titleKey, { lng: 'en' }),
        },
        type: 'purchase_order',
        total_count: totalCount,
        locale,
      },
      file_key: key,
      token,
    },
  );
};
const useCreateBulkPurchaseOrdersJob = () => {
  const { merchantId } = useParams<{ merchantId: string }>();
  const { t: translate, i18n } = useTranslation(['purchaseOrderBulkImport']);
  return useMutation(
    ({
      fields,
      mode,
      totalCount,
    }: {
      fields: Payload;
      mode: string;
      totalCount: number;
    }) =>
      createJob({
        merchantId,
        fields,
        mode,
        totalCount,
        translate,
        locale: i18n.language,
      }),
  );
};

export default useCreateBulkPurchaseOrdersJob;
