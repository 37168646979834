import { OptionProps } from '@shopline/dashboard-ui/types/components/dropdown/Dropdown';
import axios from 'axios';
import { useQuery, type UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

export type ProductCategory = {
  id: string;
  name_translations: { [lang: string]: string };
};

export interface ProductCategories extends QueryListResult<ProductCategory> {}

const getProductCategories = async (
  merchantId: string,
): Promise<ProductCategories> => {
  const { data } = await axios.get<ProductCategories>(
    `/api/merchants/${merchantId}/product-categories`,
  );
  return data;
};

const useQueryProductCategories = (
  options: Pick<
    UseQueryOptions<ProductCategories, Error, OptionProps[]>,
    'select'
  >,
) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<ProductCategories, Error, OptionProps[]>(
    'product_categories',
    () => getProductCategories(merchantId),
    {
      enabled: !!merchantId,
      ...options,
    },
  );
};

export default useQueryProductCategories;
