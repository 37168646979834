import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import Text, { IProps as ITextProps } from 'components/Text';
import { withProps } from 'recompose';

const Container = styled.div`
  margin-top: 1rem;
  flex: 1 1 0;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  ${media.mobile`
    flex: 1 0 100%;
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
  `}
`;

type InjectedHeadingPropKeys = 'as' | 'fontType' | 'color';

const Heading = withProps<
  Pick<ITextProps, InjectedHeadingPropKeys>,
  Omit<ITextProps, InjectedHeadingPropKeys>
>({
  as: 'h4',
  fontType: 'Caption',
  color: 'INK_800',
})(Text);

const StyledText = styled(Heading)`
  margin-bottom: 0.25rem;
  display: block;
`;

const Field: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  title,
  children,
}) => (
  <Container>
    <StyledText>{title}</StyledText>
    {children}
  </Container>
);

export default Field;
