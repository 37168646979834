import { useRef, useMemo } from 'react';
import { AnchorLink } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

type IAnchorLinkProps = Props<typeof AnchorLink>;

const useNav = ({ e2ePrefix }: { e2ePrefix: string }) => {
  const { t } = useTranslation('main');
  const configSectionRef = useRef<HTMLElement>(null);
  const items: IAnchorLinkProps['items'] = useMemo(
    () => [
      {
        title: t('Config'),
        e2eId: `${e2ePrefix}-config`,
        target: configSectionRef,
        content: t('Config'),
      },
    ],
    [t, e2ePrefix],
  );

  return {
    refs: {
      configSectionRef,
    },
    items,
  };
};

export default useNav;
