import styled from 'styled-components';
import prop from 'lodash/fp/prop';
import { media } from '@shopline/dashboard-ui';
import Text, { IProps as ITextProps } from 'components/Text';
import { withProps } from 'recompose';

const Container = styled.section`
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: ${prop('theme.colors.SNOW_DEFAULT')};
  box-shadow: 0px 0px 1px ${prop('theme.colors.SHADOW_DEFAULT')},
    0px 1px 4px ${prop('theme.colors.SHADOW_LIGHT')};
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  ${media.mobile`
    margin-top: 0.5rem;
  `}
  &:first-child {
    margin-top: 0;
  }
`;

export const Subtitle = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

type InjectedCaptionTextPropKeys = 'as' | 'fontType' | 'color';

const CaptionText = withProps<
  Pick<ITextProps, InjectedCaptionTextPropKeys>,
  Omit<ITextProps, InjectedCaptionTextPropKeys> & {
    color?: ITextProps['color'];
  }
>(({ color }) => ({
  as: 'div',
  fontType: 'Caption',
  color: color || 'INK_500',
}))(Text);

export const Caption = styled(CaptionText)`
  flex: 0 0 100%;
  margin-top: 0.25rem;
`;

const Section: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({
  title,
  children,
  ...props
}) => (
  <Container {...props}>
    <Text as="h3" fontType="DisplaySmall" color="INK_800">
      {title}
    </Text>
    {children}
  </Container>
);

export default Section;
