import { useState } from 'react';
import type { WorkBook, WorkSheet } from 'xlsx';
import useSelectXlsxFile, { XlsxFileErrorTypes } from 'hooks/useSelectXlsxFile';
import { UseSelectFileResponse } from 'hooks/useSelectFile';
import { MaxNumSelectedItems, PurchaseOrdersErrorTypes } from '../constants';

export type ErrorTypes = PurchaseOrdersErrorTypes | XlsxFileErrorTypes;

type TotalCount = { totalCount: number };

type Response = UseSelectFileResponse<ErrorTypes> & TotalCount;

export const handlePurchaseOrdersXlsx = (workSheet: WorkSheet): number => {
  const defaultFilterCells = ['A1', 'A2'];
  const orderCounts = new Set(
    Object.keys(workSheet)
      .filter((key) => /^A\d+$/.test(key) && !defaultFilterCells.includes(key))
      .map((key) => workSheet[key].w)
      .map((value) => value.trim()),
  ).size;
  return orderCounts;
};

const INITIAL_TOTAL_COUNT = 0;

const useSelectPurchaseOrdersXlsxFile = (): Response => {
  const [totalCount, setTotalCount] = useState(INITIAL_TOTAL_COUNT);
  return {
    ...useSelectXlsxFile<ErrorTypes>({
      maxSize: 10,
      onReadXlsx: (workbook: WorkBook) => {
        const errors = [];
        try {
          const firstWorkSheet = workbook.Sheets[workbook.SheetNames[0]];
          const orderCounts = handlePurchaseOrdersXlsx(firstWorkSheet);
          setTotalCount(totalCount);
          if (orderCounts > MaxNumSelectedItems) {
            errors.push(PurchaseOrdersErrorTypes.InvalidOrderCounts);
          }
        } catch (e) {
          setTotalCount(INITIAL_TOTAL_COUNT);
          errors.push(XlsxFileErrorTypes.InvalidContent);
        } finally {
          return {
            errors,
          };
        }
      },
    }),
    totalCount,
  };
};

export default useSelectPurchaseOrdersXlsxFile;
